import { useCyclesData } from '~/features/cycles';
import { useUserTokensQuery } from '../queries';

export function useTokens() {
  const { uid } = useUserState();
  const {
    data: tokens,
    isLoading,
    suspense,
    error,
  } = useUserTokensQuery(uid.value);
  const { currentCycle } = useCyclesData();
  const now = useNow();

  const currentCycleTokens = computed(() =>
    (tokens.value ?? []).filter((token) => {
      if (!currentCycle.value) return false;

      return (
        token.createdAtTimeMs >= currentCycle.value.startDate &&
        token.createdAtTimeMs <= currentCycle.value.endDate
      );
    })
  );
  const startingBalance = computed(() => currentCycleTokens.value.length);

  const validTokens = computed(() => {
    const currentCycleStartTimeMs = currentCycle.value?.startDate || 0;
    const currentCycleEndTimeMs = currentCycle.value?.endDate || 0;

    return (tokens.value ?? []).filter(
      (token) =>
        token.createdAtTimeMs >= currentCycleStartTimeMs &&
        token.createdAtTimeMs < currentCycleEndTimeMs &&
        !token.used &&
        token.expiresAtTimeMs > now.value
    );
  });

  const validTokenCount = computed(() => validTokens.value?.length ?? 0);

  const validTokensExpiresAtMs = computed(() => {
    // assuming all valid tokens will have the same expiration date
    return validTokens?.value?.[0]?.expiresAtTimeMs;
  });

  return {
    tokens,
    validTokens,
    validTokenCount,
    validTokensExpiresAtMs,
    startingBalance,
    isLoading,
    error,
    suspense,
  };
}
